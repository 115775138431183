<template>
<div class="el-content">
    <a-row>
        <a-col :span="8">
            <div>
                <a-image width="375px" :src="$util.getStaticSrc('admin/land-lug-1.png')"></a-image>
            </div>
            <div>
                <a-image width="375px" :src="$util.getStaticSrc('admin/land-lug-2.png')"></a-image>
            </div>

        </a-col>
        <a-col :span="12">
            <a-form :label-col="{span:4}" :warpper-col="{span:16}">
                <a-form-item label="土地列表 1">
                    <a-input v-model:value="form.land_title1"></a-input>
                </a-form-item>
                <a-form-item label="选择土地 2">
                    <a-input v-model:value="form.land_button1"></a-input>
                </a-form-item>
                <a-form-item label="土地详情 1">
                    <a-input v-model:value="form.land_title2"></a-input>
                </a-form-item>
                <a-form-item label="选择地块 2">
                    <a-input v-model:value="form.land_label1"></a-input>
                </a-form-item>
                <a-form-item label="租赁周期 3">
                    <a-input v-model:value="form.land_label2"></a-input>
                </a-form-item>
                <a-form-item label="地块服务 4">
                    <a-input v-model:value="form.land_label3"></a-input>
                </a-form-item>
                <a-form-item label="种子列表 5">
                    <a-input v-model:value="form.land_label4"></a-input>
                </a-form-item>
                <a-form-item label="土地详情 6">
                    <a-input v-model:value="form.land_label5"></a-input>
                </a-form-item>
                <a-form-item label="立即租赁 7">
                    <a-input v-model:value="form.land_button2"></a-input>
                </a-form-item>
                <a-form-item label="免费领土地 8">
                    <a-input v-model:value="form.land_button3"></a-input>
                </a-form-item>
                <a-form-item :wrapper-col="{offset:4}">
                    <a-button type="primary" @click="saveAdoptLug">保存</a-button>
                </a-form-item>
            </a-form>
        </a-col>
    </a-row>
</div>
</template>

<script>
import {reactive, toRefs} from "vue";
import setModel from "@/api/set";
import tool from "@/util/tool";

export default {
    setup(){
        // 第一页
        // land_title1 土地列表
        // land_button1 选择土地
        // 第二页
        // land_title2 土地详情
        // land_button2 立即租赁
        // land_button3 免费领土地
        // land_label1 选择地块
        // land_label2 租赁周期
        // land_label3 地块服务
        // land_label4 种子列表
        // land_label5 土地详情

        let state = reactive({
            form:{
                land_title1:"",
                land_button1:"",
                land_title2:"",
                land_button2:"",
                land_button3:"",
                land_label1:"",
                land_label2:"",
                land_label3:"",
                land_label4:"",
                land_label5:"",
            }
        })

        setModel.getFarmSet(Object.keys(state.form)).then(res=>{
            state.form = tool.filterForm(Object.keys(state.form),res)
        })

        function saveAdoptLug(){
            setModel.setFarmSet(state.form)
        }

        return {
            ...toRefs(state),
            saveAdoptLug
        }
    }
}
</script>

<style scoped>

</style>
